import React from "react"
import styled from "styled-components"

import {
  Article,
  Benefits,
  OptionalBenefits,
  SEO,
  Perks,
  Affordable,
  Conclusion,
} from "../../sections/products/comprehensive-funeral"
import Versatile from "../../sections/products/comprehensive-funeral/Versatile"

const ComprehensiveFuneralBenefit: React.FC = () => (
  <PageWrapper className="touchFooter">
    <SEO />
    <Article />
    <Affordable />
    <Versatile />
    <Benefits />
    <OptionalBenefits />
    <Conclusion />
  </PageWrapper>
)

export default ComprehensiveFuneralBenefit

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`
