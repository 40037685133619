import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const Benefits = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Benefits of Comprehensive Funeral Product"
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Double Payout on Accidental Death",
          label:
            "Our product offers a double payout on accidental death, with coverage of up to R60,000. This ensures that your family receives additional support in unexpected situations.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Premium Waiver on Death",
          label:
            "In the unfortunate event of the main member's passing, we offer a premium waiver of 60 months to provide financial relief to the family during this challenging period.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Once-off Airtime Benefit",
          label:
            "We understand that communication is crucial during such times. That's why we offer a once-off airtime benefit of R250, ensuring that you can stay connected when you need it the most.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-4.png`} {...ImageProperties} />
          ),
          topic: "Grocery Voucher",
          label:
            "As a token of our support, we provide a grocery voucher to the value of R1,000. This practical benefit can help with daily expenses during challenging times.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-5.png`} {...ImageProperties} />
          ),
          topic: "Repatriation Benefit",
          label:
            "Our product includes a repatriation benefit of R7,500 per claim, up to a maximum of R20,000 per year. This benefit ensures that your loved ones can be transported home for a dignified burial, even if they're far away.",
        },
      ]}
      reverse={true}
    />
  )
}

export default Benefits
