import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const Affordable = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Affordable Funeral Cover"
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Affordable Premiums",
          label:
            "One of the key advantages of the Comprehensive Funeral Benefit is its affordability. With premiums starting as low as R112 per month*, you can access comprehensive funeral cover without straining your budget.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Cover for Your Entire Family",
          label:
            "This product extends its coverage to include not just you, but your entire family. You, your spouse, up to three children, and extended family members are all included.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Dignified Burials",
          label:
            "We understand the importance of a dignified burial, and that's what our cover is all about. Your family deserves to say their farewells without the burden of financial concerns.",
        },
      ]}
    />
  )
}

export default Affordable
