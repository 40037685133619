import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const Versatile = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Versatile Cover Options"
      color={PRODUCT_COLOR}
      className="slider"
      coverType={COVER_LINK}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "No Medical Examination Required",
          label:
            "Applying for the Comprehensive Funeral Benefit is hassle-free. You don't need to undergo a medical examination, making the process more convenient.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Maximum Cover of Up to R30,000",
          label:
            "With this product, you can get a maximum cover of up to R30,000. This coverage ensures that you have the financial support you need during challenging times.",
        },
      ]}
      reverse={true}
    />
  )
}

export default Versatile
