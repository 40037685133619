import React from "react"
import styled from "styled-components"

import { COVER_LINK, PRODUCT_COLOR } from "."
import { CoverButton } from "../../../elements"

const Conclusion = (): JSX.Element => {
  return (
    <Container>
      <div className="sectionHeader">
        <h2>Secure a Dignified Farewell</h2>
        <p>We help you build a portfolio that caters to your needs.</p>
      </div>

      <p>
        The Workerslife Comprehensive Funeral Benefit is more than just
        insurance; it&apos;s a promise of financial peace of mind during
        challenging times. With a cover designed for you, your family, and
        extended family members, it ensures that a dignified farewell is
        guaranteed. When you choose Workerslife, you&apos;re choosing to protect
        your loved ones and ensure that they receive the support they deserve.
      </p>
      <div className="buttonContainer">
        <CoverButton
          coverType={COVER_LINK}
          filled
          buttonColor={PRODUCT_COLOR}
          textColor="#fff"
          label="Get Covered"
        />
      </div>
      <p className="styledFooter">
        Invest in the Comprehensive Funeral Benefit and secure affordable
        funeral insurance that goes beyond just covering expenses. Give your
        family the peace of mind they deserve during difficult times.
      </p>
    </Container>
  )
}

export default Conclusion

const Container = styled.div`
  p {
    padding: 0 30px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
  .styledFooter {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }
  .coverButton {
    margin: 60px auto;
  }
  .buttonContainer {
    padding: 0 20px;
  }
  .sectionHeader {
    margin: 60px auto 40px auto;
    max-width: 970px;
    padding: 0 30px;

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      margin: 40px auto 90px auto;

      p {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 100px auto 40px auto;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 auto 90px auto;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .buttonContainer {
      padding: 0;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    p,
    .sectionHeader {
      padding: 0 60px;
    }
  }
`
