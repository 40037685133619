import React from "react"
import { SEO } from "../../../components"
import { ProductJsonLd } from "../GoogleSEO"

const PRODUCT_NAME = "Comprehensive Funeral Benefit"
const DESCRIPTION =
  "Secure your family's future with our Comprehensive Funeral Benefit. Dignified funeral coverage for South Africans. Learn more today."

const ProductSEO = (): JSX.Element => (
  <>
    <SEO title={PRODUCT_NAME} description={DESCRIPTION} />
    <ProductJsonLd
      name={PRODUCT_NAME}
      description={DESCRIPTION}
      lowPrice={112}
    />
  </>
)

export default ProductSEO
