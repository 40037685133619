import theme from "../../../theme"
export const PRODUCT_COLOR = theme.palette.custom.yellow

import { CoverType } from "../../../elements/CoverButton"
export const COVER_LINK = CoverType.COMPREHENSIVE_FUNERAL

export { default as Article } from "./Article"
export { default as Benefits } from "./Benefits"
export { default as OptionalBenefits } from "./OptionalBenefits"
export { default as Perks } from "./Perks"
export { default as SEO } from "./SEO"
export { default as Affordable } from "./Affordable"
export { default as Conclusion } from "./Conclusion"
