import React from "react"
import styled from "styled-components"

import { ProductArticleExtraContent } from "../../../components"

import { COVER_LINK, PRODUCT_COLOR } from "."

const Article = (): JSX.Element => {
  return (
    <Container>
      <ProductArticleExtraContent
        color={PRODUCT_COLOR}
        title="Comprehensive Funeral Benefit"
        coverType={COVER_LINK}
        subTitle="Discover the Workerslife Comprehensive Funeral Benefit, offering peace of mind at affordable premiums. Cover yourself, your family, and extended family members with diverse benefits, ensuring a dignified burial."
        topic="Affordable Cover for a Dignified Farewell"
      >
        <p>
          Losing a loved one is never easy, and the last thing you should worry
          about during such times is the financial burden of a funeral.
          Workerslife understands the importance of providing affordable funeral
          cover to ensure a dignified farewell. That&apos;s why we offer the
          Comprehensive Funeral Benefit, designed to provide peace of mind to
          you, your spouse, up to three children, and extended family members.
          With premiums starting as low as R112 per month*, this product offers
          diverse benefits that go beyond just covering expenses. It&apos;s a
          promise of a dignified burial.
        </p>
      </ProductArticleExtraContent>
    </Container>
  )
}

export default Article

const Container = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
`
