import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { ColorTiles, ProductTilesWrapper } from "../../../components"

import { PRODUCT_COLOR } from "./"

export const PERKS = [
  {
    opacity: "0.6",
    header: "No medical examination required.",
  },
  {
    opacity: "0.3",
    header: "Get a maximum cover of up to R30 000.",
  },
]

const query = graphql`
  {
    bgImage: file(
      relativePath: { eq: "products/comprehensive-funeral-benefit/perks.png" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Perks = (): JSX.Element => {
  const data = useStaticQuery(query)
  const imageData = data.bgImage.childImageSharp.fluid

  return (
    <ProductTilesWrapper color={PRODUCT_COLOR} tileType="text-only">
      <ColorTiles
        backgroundImage={imageData}
        tiles={PERKS.map(perk => ({
          tileType: "text-only",
          color: PRODUCT_COLOR,
          ...perk,
        }))}
      />
    </ProductTilesWrapper>
  )
}

export default Perks
