import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"

import { ProductTilesWrapper } from "../../../components"

import { CoinPileIcon } from "../../../assets/svg"

import { PRODUCT_COLOR } from "."
import ColorTilesRevamped from "../../../components/ColorTilesRevamped"

export const BENEFITS = [
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Grocery Benefit",
        text: "You can opt for an additional grocery benefit of R1,000 for a period of 3 days. This benefit ensures that you have support for daily essentials.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Memorial Benefit",
        text: "We also offer a memorial benefit to help you remember your loved ones. It's a small but meaningful gesture to provide additional support during your grieving process.",
      },
    ],
  },
]

const query = graphql`
  {
    bgImage: file(
      relativePath: {
        eq: "products/comprehensive-funeral-benefit/optional-benefits.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const OptionalBenefits = (): JSX.Element => {
  const data = useStaticQuery(query)
  const imageData = data.bgImage.childImageSharp.fluid

  return (
    <Container>
      <h2>Additional benefits available on the Legal App...</h2>
      <ProductTilesWrapper color={PRODUCT_COLOR} tileType="icon">
        <BackgroundImage fluid={imageData} preserveStackingContext>
          <ColorTilesRevamped
            tiles={BENEFITS.map(benefit => ({
              tileType: "text-with-icon",
              color: PRODUCT_COLOR,
              icon: <CoinPileIcon />,
              ...benefit,
            }))}
          />
        </BackgroundImage>
      </ProductTilesWrapper>
    </Container>
  )
}

export default OptionalBenefits

const Container = styled.div`
  h2 {
    margin-bottom: 78px;
    text-align: center;
    padding: 0 15px;
  }
`
const Disclaimer = styled.p`
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06em;
  margin-top: 25px;
  padding: 0 15px;

  a {
    font-family: "SofiaPro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`
